<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    :name="getName"
    :ref="customRef"
  >
    <v-row
      class="input-normal"
      align-content="center"
      :no-gutters="label ? false : true"
      :class="isProtected ? 'field__not-editable' : ''"
    >
      <v-col
        cols="12"
        :md="labelCols"
        v-if="label"
        :class="{ 'field__label-column': true, 'py-1': twoLines }"
      >
        <font-awesome-icon
          :icon="{ prefix: 'fab', iconName: icon }"
          class="mr-2"
          v-if="icon"
        />
        <label :class="{ 'label-secondary': isLabelSecondary }" for=""
          >{{ label }}
          <span :class="{ 'red-label': isRedLabel }">
            {{ isRequired && !disabled ? "*" : "" }}
          </span></label
        >
      </v-col>

      <v-col
        cols="12"
        :md="inputCols"
        style="flex: 1;"
        class="mb-3"
        :class="{ 'field__value-column': true, 'pt-0 pb-1': twoLines }"
      >
        <span v-if="isProtected" class="not-editable">
          {{ protectedText }}
        </span>

        <input
          v-else
          :id="getId"
          v-model="currentValue"
          :type="type"
          :step="step"
          :placeholder="placeholder"
          :class="getClass(errors)"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :max="maxDate"
          :min="minDate || minValue"
          @change="change"
          @keydown="($event) => keydown($event)"
          @keypress="checkInput($event)"
          @blur="$emit('blur', $event)"
          @focus="$emit('on-focus')"
          @mouseout="$emit('focus-out')"
          ref="input"
          :style="inputStyle"
          :readonly="readonly"
          :error-messages="errorMessage"
        />
        <span v-if="errorMessage" class="form__error-message">{{
          errorMessage
        }}</span>
        <span v-else class="form__error-message">{{ errors[0] }}</span>
        <slot />
      </v-col>
    </v-row>
  </validation-provider>
</template>

<script>
import { string_to_slug } from "@/plugins/utils.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    customRef: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    custom_class: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "false",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },

    step: {
      type: [String, Number],
      default: 1,
    },

    twoLines: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    isProtected: {
      type: Boolean,
      default: false,
    },
    protectedText: {
      type: [String, Boolean],
      default: "",
    },

    maxDate: {
      type: String,
      default: null,
    },

    minDate: {
      type: String,
      default: null,
    },
    inputStyle: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isLabelSecondary: {
      type: Boolean,
      default: false,
    },
    isRedLabel: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    minValue: {
      type: Number,
      default: null,
    },
  },

  methods: {
    getClass(errors) {
      let futurClasses = "";
      if (errors[0]) {
        futurClasses += " is-invalid ";
      }

      futurClasses += this.custom_class;

      if (this.fullWidth) {
        futurClasses += " full-width ";
      }

      return futurClasses;
    },
    change(item) {
      this.$emit("onChange", item);
    },
    keydown(evt) {
      if (this.type === "number") {
        var invalidChars = ["-", "+", "e"]; //include "." if you only want integers
        if (invalidChars.includes(evt.key)) {
          evt.preventDefault();
        }
      }
      this.$emit("keydown", evt);
    },

    setValue(value) {
      this.currentValue = value;
    },

    checkInput: function(evt) {
      if (this.type == "number") {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46 &&
          charCode !== 44
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },

  computed: {
    getId() {
      if (this.id) {
        return this.id;
      }
      return (
        "input_" +
        this.type +
        "_" +
        Math.floor(Math.random() * 100) +
        "_" +
        string_to_slug(this.getName)
      );
    },

    getName() {
      if (this.name) {
        return this.name;
      }
      return this.label.toLowerCase();
    },

    isRequired() {
      return this.rules.indexOf("required") !== -1;
    },

    inputCols() {
      if (this.twoLines) {
        return "12";
      }
      return this.label ? "9" : "12";
    },

    labelCols() {
      return this.twoLines ? "12" : "3";
    },

    watchedCurrentValue() {
      return this.currentValue;
    },
  },

  mounted() {
    this.currentValue = this.value;
  },

  data: () => ({
    currentValue: "",
  }),

  watch: {
    currentValue(val, oldVal) {
      this.$emit("input", val);
    },

    value(val) {
      this.currentValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-normal {
  font-family: roboto !important;
}
.label-secondary {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.red-label {
  color: red;
}
</style>
