<template>
  <ValidationObserver ref="validationForm" v-slot="{errors, invalid}">
    <v-alert
      v-show="error && isNeeded"
      color="red"
      icon="info"
      show
      type="error"
      border="left"
      text
      transition="scroll-y-transition"
      >{{ errorMessage }}</v-alert
    >
    <form @submit.prevent="onSubmit()" ref="form" :disabled="loading">
      <div>
        <div
          v-if="showFieldObl"
          class="pb-2"
          style="font-size: 0.7rem; color: grey;"
        >
          (<span style="color: red;">*</span> Champs obligatoires)
        </div>
        <slot :errors="errors" :invalid="invalid" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      inputs: [],
    };
  },

  props: {
    errorMessage: {
      type: String,
      default: "Merci de corriger les champs",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showFieldObl: {
      type: Boolean,
      default: true,
    },
    isNeeded: {
      type: Boolean,
      default: true,
    },
  },

  created() {},
  mounted() {
    if (this.loading) {
      this.disableInputs();
    }
  },

  methods: {
    /**
     * Being normally called by the parent!
     */
    resetErrors() {
      if (this.$refs.validationForm) {
        this.$refs.validationForm.reset();
        this.error = false;
      }
    },

    async onSubmit() {
      await this.$refs.validationForm.validate().then((validate) => {
        if (!validate) {
          this.error = true;
          this.$emit("onError");
        } else {
          this.error = false;
          this.$emit("validated");
        }
      });
    },

    disableInputs() {
      if (!this.inputs.length) {
        this.inputs = this.$refs.form.querySelectorAll("input");
      }

      this.inputs.forEach((input) => {
        input.disabled = true;
        input.style.opacity = 0.5;
      });
    },

    enableInputs() {
      if (!this.inputs.length) {
        this.inputs = this.$refs.form.querySelectorAll("input");
      }

      this.inputs.forEach((input) => {
        input.disabled = false;
        input.style.opacity = 1;
      });
    },
  },

  watch: {
    loading(isLoading) {
      if (isLoading) {
        this.disableInputs();
      } else {
        this.enableInputs();
      }
    },
  },
};
</script>

<style>
.v-alert {
  font-weight: 200;
  font-size: 0.9rem;
}
</style>
