const ClubAssociation = 1;
const LicencieHorsClub = 2;
const ClubEtablissementAffilie = 3;
const ComiteTerritorial = 4;
const Ligue = 5;
const ClubMembreAssocie = 6;
const ServiceFederalFfme = 7;
const ComiteSportif = 8;
const ClubCaf = 9;
const ClubSectionAssociation = 10;

export default {
  ClubAssociation: ClubAssociation,
  LicencieHorsClub: LicencieHorsClub,
  ClubEtablissementAffilie: ClubEtablissementAffilie,
  ComiteTerritorial: ComiteTerritorial,
  Ligue: Ligue,
  ClubMembreAssocie: ClubMembreAssocie,
  ServiceFederalFfme: ServiceFederalFfme,
  ComiteSportif: ComiteSportif,
  ClubCaf: ClubCaf,
  ClubSectionAssociation: ClubSectionAssociation,
}
