import axios from "axios";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

const addStructureToCampaign = async (params) => {
  try {
    const response = await axios.post(
      process.env.VUE_APP_FFME_BACK_URL + "/api/campaigns/add_structure",
      params,
      {
        headers: getHeaders()
      }
    );

    const json = await response.data;

    return { ok: true };
  } catch (error) {
    return { ok: false };
  }
};

export {
  addStructureToCampaign,
};
