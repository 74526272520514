<template>
  <div style="width: 100%;">
    <SyntheseAfilliation
      style="width: 100%;"
      :context="{
        ID_Structure: currentStructureId,
        ID_Saison: selectedSeason.id,
      }"
    ></SyntheseAfilliation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SyntheseAfilliation from "@/components/Affiliations/Affiliation__Synthese.vue";

export default {
  components: { SyntheseAfilliation },

  data: () => ({
    selectedSaison: null,
  }),

  async created() {
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["saisons"]),
  },
};
</script>
