export const DELEGATE_MANDATE = 'delegate_mandate';
export const CODIR_REPORT = 'codir_report';
export const BALANCE_SHEET = 'balance_sheet';
export const BUDGET = 'budget';
export const RESULT_REPORT = 'result_report';
export const GENERAL_MEETING_REPORT = 'general_meeting_report';
export const RIB = 'rib';
export const JO_PUBLICATION = 'jo_publication';
export const SEPA_MANDATE = 'sepa_mandate';
export const STATUS = 'status';
export const RECEPISSE_PREFECTORAL_DECLARATION = 'recepisse_prefectoral_declaration';
export const INTERNAL_RULES = 'internal_rules';
export const SUPERVISOR_DIPLOMA = 'supervisor_diploma';
export const PROFESSIONAL_CARD = 'professional_card';
export const INSURANCE_CERTIFICATE = 'insurance_certificate';
export const TREASURY_REPORT = 'treasury_report';

export const structureDocumentTypeLabel = {
  [DELEGATE_MANDATE]: 'Mandat délégué',
  [CODIR_REPORT]: 'Compte rendu de CODIR',
  [BALANCE_SHEET]: 'Bilan financier',
  [BUDGET]: 'Budget',
  [RESULT_REPORT]: 'Compte résultats',
  [GENERAL_MEETING_REPORT]: 'Compte rendu d\'AG',
  [RIB]: 'RIB',
  [JO_PUBLICATION]: 'Parution JO',
  [SEPA_MANDATE]: 'Mandat SEPA',
  [STATUS]: 'Statut',
  [RECEPISSE_PREFECTORAL_DECLARATION]: 'Récépissé de déclaration préfectorale',
  [INTERNAL_RULES]: 'Règlement intérieur',
  [SUPERVISOR_DIPLOMA]: 'Diplôme encadrant',
  [PROFESSIONAL_CARD]: 'Carte professionnelle',
  [INSURANCE_CERTIFICATE]: 'Attestation d\'assurance',
  [TREASURY_REPORT]: 'Rapport du trésorier',
};

export const docDocumentIdDocToStructureDocumentType = {
  11: STATUS,
  12: GENERAL_MEETING_REPORT,
  13: TREASURY_REPORT,
  27: RIB,
  28: JO_PUBLICATION,
  29: RECEPISSE_PREFECTORAL_DECLARATION,
  30: INTERNAL_RULES,
  31: STATUS,
  32: STATUS,
  33: GENERAL_MEETING_REPORT,
  34: GENERAL_MEETING_REPORT,
  35: TREASURY_REPORT,
  36: SUPERVISOR_DIPLOMA,
  37: PROFESSIONAL_CARD,
  38: INSURANCE_CERTIFICATE,
  39: TREASURY_REPORT,
  40: INTERNAL_RULES,
  41: STATUS,
  47: BALANCE_SHEET,
  48: RESULT_REPORT,
  49: BALANCE_SHEET,
  50: RESULT_REPORT,
  51: RIB,
  52: SEPA_MANDATE,
  54: DELEGATE_MANDATE,
};

export const structureDocumentTypeToDocDocumentId = Object.entries(docDocumentIdDocToStructureDocumentType).reduce((acc, [key, value]) => {
  if (!acc[value]) {
    acc[value] = [];
  }

  acc[value].push(Number(key));

  return acc;
}, {});
