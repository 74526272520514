<template>
  <div style="padding-left: 40px; padding-top: 40px; width: 600px !important;">
    <v-card class="mx-auto" width="600" tile>
      <v-list flat>
        <v-subheader><h6>Synthèse</h6></v-subheader>
        <v-skeleton-loader
          v-if="!mounted"
          :loading="true"
          :boilerplate="false"
          type="list-item-avatar@6"
          tile
          class="mx-auto"
        />
        <div v-else>
          <itemList
            :checked="information_club"
            title="Informations générales"
          />
          <itemList :checked="club_activity" title="Activités du club" />
          <itemList :checked="document_ag" title="Documents à joindre" />
          <itemList
            :checked="information_salaries"
            title="Gestion des salariés"
          />
        </div>
      </v-list>

      <!-- new reaffiliation state -->
      <div v-if="isNotAffiliated" class="d-flex mx-4">
        <div class="checkbox-container">
          <v-checkbox
            v-model="validation1"
            label="Je certifie pouvoir représenter légalement l’association."
          ></v-checkbox>
        </div>
      </div>
      <div v-if="validation1" class="mx-4">
        <div class="d-flex align-center my-2">
          <h6>Mandat du représentant légal</h6>
          <v-chip
            @click.prevent="modalIsOpen_DOC_RIB = true"
            class="ml-5"
            style="margin-top: -10px;"
            small
            >{{ currentFileName() }}</v-chip
          >
          <ModalFiles
            :key="modalKey_DOC_RIB"
            :isOpen="modalIsOpen_DOC_RIB"
            @onClose="modalIsOpen_DOC_RIB = false"
            @onFileSelected="(file) => fileSelected(file)"
          ></ModalFiles>
        </div>
        <div class="d-flex">
          <v-checkbox
            v-model="validation2"
            :readonly="!openedLink.some((link) => link === 1)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance du
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/contrat-engagement-republicain.pdf',
                          1
                        )
                      "
                      style="color: #1976d2; text-decoration: underline;"
                      v-on="on"
                      v-text="'contrat d’engagement républicain* '"
                    />
                  </template>
                  Veuillez consulter le dit contrat pour valider l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ledit contrat.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div
          class="d-flex"
          v-if="currentStructure.STR_Structure.LIST_StructureType.id === 1"
        >
          <v-checkbox
            v-model="validation3"
            :readonly="!openedLink.some((link) => link === 2)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance du
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/contrat-club.pdf',
                          2
                        )
                      "
                      style="color: #1976d2; text-decoration: underline;"
                      v-on="on"
                      v-text="'contrat club* '"
                    />
                  </template>
                  Veuillez consulter le dit contrat pour valider l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ledit contrat.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div
          v-if="currentStructure.STR_Structure.LIST_StructureType.id === 3"
          class="d-flex"
        >
          <v-checkbox
            v-model="validation4"
            :readonly="!openedLink.some((link) => link === 3)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance de la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/convention-etablissement.pdf',
                          3
                        )
                      "
                      style="color: #1976d2; text-decoration: underline;"
                      v-on="on"
                      v-text="'convention d’établissement* '"
                    />
                  </template>
                  Veuillez consulter la dite convention pour valider
                  l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ladite convention.
              </div>
            </template>
          </v-checkbox>
        </div>
        <span style="font-size: 12px; font-weight: 300; opacity: 0.6;">* Cliquez sur les contrats pour confirmer l’engagement du club</span>
      </div>

      <div class="text-left pt-4 pb-6 px-10" v-if="mounted">
        <p>
          Le montant de l'affiliation pour la saison {{ this.context.ID_Saison }} s'élève à {{ tarifStructure / 100 }}€
        </p>

        <p v-if="isNextSeason">
          Vous procédez actuellement à une ré-affiliation pour la saison {{ this.context.ID_Saison }}.
          A noter que ce montant sera recalculé au premier septembre {{ this.context.ID_Saison - 1 }} en fonction du nombre
          total de licences délivrées au cours de la saison {{ this.context.ID_Saison - 1 }} et sera prélevé à partir du
          début de la saison {{ this.context.ID_Saison }}.
        </p>

        <v-btn
          v-if="canAffiliate"
          type="submit"
          :disabled="!canSubmit"
          @click.prevent="openConfirmModal"
          :loading="loading"
          class="btn btn-primary mt-5"
        >
          Valider ma ré-affiliation
        </v-btn>
        <div v-else class="font-weight-bold green--text mt-5">
          Affiliation de la saison {{ this.context.ID_Saison }} validée
        </div>
      </div>

      <modalConfirm
        :showDialog="modalConfirmIsOpen"
        @answerYes="submission"
        @answerNo="modalConfirmIsOpen = false"
        title="Valider l'affiliation"
        text="Je confirme l'exactitude des informations saisies sur les étapes précédentes."
        text_yes="Valider l'affiliation"
        text_no="Annuler"
      ></modalConfirm>
      <PrintFacture ref="facture" :print="print"></PrintFacture>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import itemList from "@/components/Affiliations/Affiliation__SyntheseItemList.vue";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import {send_email} from "@/plugins/utils";
import modalConfirm from "@/components/utils/modals/yes-no.vue";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import ModalFiles from "@/components/utils/modals/fileUpload/fileUpload.vue";
import {formatFluxFromAffiliation, insertFlux} from "@/plugins/fluxService";
import { addStructureToCampaign } from "@/plugins/campaignService";
import StructureType from "@/constants/structureType";
import {
  PRODUCT_AFFILIATION_CLUB,
  PRODUCT_AFFILIATION_EA,
  PRODUCT_AFFILIATION_MA,
  OPTION_AFFILIATION_0,
  OPTION_AFFILIATION_100,
  OPTION_AFFILIATION_301,
  getPrices
} from "@/plugins/productService";

import { getLicences } from "@/plugins/licenceService";
import { getStructureDocumentsByStructureId, uploadStructureDocument } from "../../plugins/structureService";
import { DELEGATE_MANDATE, GENERAL_MEETING_REPORT, INSURANCE_CERTIFICATE, PROFESSIONAL_CARD, RESULT_REPORT, SUPERVISOR_DIPLOMA } from "../../constants/structureDocumentType";

export default {
  components: { itemList, modalConfirm, PrintFacture, ModalFiles },

  data: () => ({
    mounted: false,
    utilisateurs: [],
    fonctiontList: [],
    affiliation: null,
    loading: false,
    modalConfirmIsOpen: false,
    tarifStructure: null,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
    activities: [],
    // reaffiliation
    validation1: false,
    validation2: false,
    validation3: false,
    validation4: false,
    openedLink: [],
    modalIsOpen_DOC_RIB: false,
    modalKey_DOC_RIB: 75,
    selectedFile: null,
    documentsType: null,
    currentNomCorrespondant: null,
    commentEmail: "",
    nbUserPreviousSaison: 0,
    structureDocuments: [],
  }),

  async created() {
    console.log("this.context", this.context);
    await this.getStructureAffiliation(this.context);
    this.fonctiontList = await this.getListTypeFonctions();
    await this.setData();
    this.mounted = true;
  },

  methods: {
    ...mapActions("structure", [
      "getStructureAffiliation",
      "getAffiliationTarifStructure",
      "getTarifStructure",
      "updateStatusAffiliation",
      "getFluxFinancier",
      "getStructureParent",
      "getTarifsCotisation",
      "getTarifCotisationFFME",
      "getStructureActivite",
    ]),
    ...mapActions("basics", [
      "getListTypeFonctions",
      "getCurrentAffiliationTarif",
      "getListTypesDocument",
      "insertDocumentNormal",
    ]),
    ...mapActions("utilisateurs", [
      "getUtlisateursInStructure",
      "createFluxFinancierDetail",
      "getListDirigeants",
      "countUserInStructure",
    ]),
    openLink(link, value) {
      window.open(link);
      this.openedLink.push(value);
    },
    async setData() {
      let type = await this.getListTypesDocument();

      this.documentsType = type.filter(
        (doc) =>
        doc.LIST_DocumentCategory?.SlugCategorieDocument === "documents_club"
      );

      this.structure = await this.getStructure(this.context.ID_Structure);
      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.context.ID_Saison,
      });
      this.activities = await this.getStructureActivite(
        this.context.ID_Structure
      );

      const structureDocuments = await getStructureDocumentsByStructureId(this.context.ID_Structure);
      this.structureDocuments = structureDocuments;

      this.nbUserPreviousSaison = await getLicences({
        structure: this.context.ID_Structure,
        season: this.context.ID_Saison - 1,
        active: true,
      }, true);

      let payload = {};
      let arrayClause = {
        EST_Actif: { _eq: true },
      };
      arrayClause.ID_Structure = { _eq: this.context.ID_Structure };
      payload.where = { ...arrayClause };

      this.utilisateurs = await this.getListDirigeants(payload);

      let slugProduct = '';

      if (this.structure.STR_Structure.LIST_StructureType.id === StructureType.ClubAssociation) {
        slugProduct = PRODUCT_AFFILIATION_CLUB;
      } else if (this.structure.STR_Structure.LIST_StructureType.id === StructureType.ClubEtablissementAffilie) {
        slugProduct = PRODUCT_AFFILIATION_EA;
      } else {
        slugProduct = PRODUCT_AFFILIATION_MA;
      }

      let slugOption = '';
      if (slugProduct === PRODUCT_AFFILIATION_CLUB && this.nbUserPreviousSaison < 100) {
        slugOption = OPTION_AFFILIATION_0;
      } else if (slugProduct === PRODUCT_AFFILIATION_CLUB && this.nbUserPreviousSaison > 300) {
        slugOption = OPTION_AFFILIATION_301;
      } else if (slugProduct === PRODUCT_AFFILIATION_CLUB) {
        slugOption = OPTION_AFFILIATION_100;
      }

      let prices = await getPrices({
        structureId: this.context.ID_Structure,
        seasonId: this.context.ID_Saison,
        productId: slugProduct,
        options: slugOption !== '' ? slugOption : undefined,
        date: this.isNextSeason() ? new Date(this.nextSaison.DateDebutSaison) : undefined,
      });

      this.tarifStructure = prices ? prices.reduce((carry, price) => carry + price.value, 0) : 0;

      //FIND CORRESPONDANT
      if (this.structure.STR_Structure.ID_NomCorrespondant) {
        let user = {
          ...this.utilisateurs.find(
            (user) =>
              user.ID_Utilisateur ==
              this.structure.STR_Structure.ID_NomCorrespondant
          ),
        };
        console.log("correspondant user", user);

        if (user.ID_Utilisateur) this.currentNomCorrespondant = user;
        //console.log("correspondant",this.currentNomCorrespondant)
      }

      let today = this.$moment().toISOString();
      let dateAfterSaison = this.$moment(
        `09/01/${this.context.ID_Saison - 1}`
      ).toISOString();

      if (today > dateAfterSaison) {
        this.commentEmail = `L’attestation d’affiliation est disponible sur myFFME.`;
      } else {
        this.commentEmail = `L’attestation d’affiliation sera disponible à partir du 1er septembre ${
          this.context.ID_Saison - 1
        }`;
      }
      return;
    },
    fileSelected(file) {
      this.selectedFile = { ...file };
    },
    currentFileName() {
      if (this.selectedFile) {
        return this.selectedFile.file.name;
      }
      return "Veuillez choisir un fichier";
    },

    openConfirmModal() {
      this.modalConfirmIsOpen = true;
    },
    errorSubmission() {
      this.modalConfirmIsOpen = false;
      error_notification("Une ou plusieurs cases ne sont pas cochées");
    },
    async submission() {
      if (this.currentStructure?.STR_Structure?.LIST_StructureType?.id === StructureType.ClubAssociation) {
        return this.validation2 && this.validation3
          ? await this.submitAffiliation()
          : this.errorSubmission();
      }
      if (this.validation2 && this.validation4)
        return await this.submitAffiliation();
      return this.errorSubmission();
    },
    isNextSeason() {
      return parseInt(this.context.ID_Saison, 10) === parseInt(this.nextSaison.id, 10);
    },

    async submitAffiliation() {
      this.modalConfirmIsOpen = false;
      this.loading = true;
      success_notification(
        "La signature des documents a bien été prise en compte"
      );

      if (this.selectedFile) {  
        await uploadStructureDocument(this.selectedFile.file, this.context.ID_Structure, this.context.ID_Saison, DELEGATE_MANDATE)
        .then(() => {
          this.modalUploadDocumentIsOpen = false;
          success_notification("Ficher enregistré");
        }).catch(() => {
          error_notification("Oups, une erreur est survenue lors du téléchargement du document...");
        });
      }

      this.loading = true;
      try {
        let payload = {
          ID_Structure: this.context.ID_Structure,
          ID_Saison: this.context.ID_Saison,
          ID_EtapeAffiliation: 3,
          id: this.affiliation?.id,
          isRepublicain_engagement: this.validation2,
          isContrat_Club_EA: this.validation3,
        };
        // check if the saison etape

        await this.updateStatusAffiliation(payload);

        // NEW FLUX BACK FFME
        await insertFlux(formatFluxFromAffiliation({
          structureId: this.context.ID_Structure,
          seasonId: this.context.ID_Saison,
          createdAt: this.isNextSeason() ? new Date(this.nextSaison.DateDebutSaison) : new Date(),
        }));

        await addStructureToCampaign({
          structure: `/api/structure/${this.context.ID_Structure}`,
          season: `/api/saison/${this.context.ID_Saison}`,
        })

        //SENDEMAIL
        let payloadEmail = {
          // User: this.currentNomCorrespondant.UTI_Utilisateur.id, // l'utitlisateur cible
          User: this.currentUserId,
          Structure: this.currentStructure.STR_Structure.NomStructure, // la str cible
          // Structure: this.context.user.STR_Structure.NomStructure, // la str cible
          Email: this.currentNomCorrespondant.UTI_Utilisateur.CT_Email,
          ID_Structure: this.context.ID_Structure.toString(),
          Saison: this.context.ID_Saison.toString(),
          Context: "sendemailReaffiliation",
          SlugModule: "gestion_des_licences__saisir_une_licence",
          Comment: this.commentEmail,
        };
        await send_email(payloadEmail);

        success_notification("Affiliation mis à jour");
        this.loading = false;
        await this.setData();
        this.loading = false;
        this.modalConfirmIsOpen = false;
      } catch (e) {
        console.log(e);
        // error_notification("Oups, une erreur est survenue");
        this.modalConfirmIsOpen = false;
      }
    },

    printFacture(item) {
      this.$refs.facture.printFacture(item);
    },
    getDocumentPaylod(file, slugType, structure) {
      return {
        CheminDocument: file.url,
        NomDocument: `${this.$moment().toISOString()}_${structure}_${slugType}_${
          file.filename
        }`,
        DateFinValiditeDocument: file.date,
        ID_Type_Document: this.documentsType.find(
          (doc) => doc.SlugTypeDocument === slugType
        )?.id,
        ID_Structure: this.currentStructure.STR_Structure.id,
        EST_Actif: true,
      };
    },
    async uploadFile(structure) {
      console.log("this.selectedFile :>> ", this.selectedFile);
      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: this.selectedFile?.fileDataURL,
        key: `${structure}_${hash}_${this.selectedFile?.file?.name}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      return await this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
              }
            }
        ).then((response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let freshFile = {
              url: response.data.location,
              filename: this.selectedFile.file.name,
              date: this.selectedFile.validationDate,
            };

            return freshFile;
          } else {
            error_notification("Upload unsuccessful");
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },

    getDefaultDoc(slugType) {
      let doc = {
        CheminDocument: "",
        DateFinValiditeDocument: "",
        EST_Actif: true,
        EST_DocumentValide: false,
        ID_Structure: this.context.ID_Structure,
        ID_Type_Document: "",
        NomDocument: "",
      };
      let type = this.allDocumentType.find(
        (doc) => doc.SlugTypeDocument == slugType
      );
      doc.ID_Type_Document = type?.id;

      return { data: doc, isNewDoc: false };
    },
  },

  computed: {
    ...mapGetters("structure", ["getStructure", "currentStructure"]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["getSaison", "currentSaison", "nextSaison"]),
    ...mapGetters("user", ["currentUserId"]),

    canAffiliate() {
      return this.affiliation.ID_EtapeAffiliation == 1;
    },
    isNotAffiliated() {
      return (
        this.currentStructure?.STR_Structure?.LIST_EtapeAffiliation?.id !== 3
      );
    },

    canSubmit() {
      if (
        this.information_club &&
        this.information_salaries &&
        this.document_ag &&
        this.validation1 &&
        this.validation2 &&
        ((this.validation3 && this.currentStructure?.STR_Structure?.LIST_StructureType?.id === StructureType.ClubAssociation) ||
        (this.validation4 && this.currentStructure?.STR_Structure?.LIST_StructureType?.id !== StructureType.ClubAssociation))
        // && this.gestion_tarifs // && this.groupes_pratique && this.seance_entrainement
      ) {
        return true;
      }
      return false;
    },

    information_club() {
      let presidentType = this.fonctiontList.find(
        (fct) => fct.SlugFonction == "president"
      );
      let secretaireType = this.fonctiontList.find(
        (fct) => fct.SlugFonction == "bureau_secretaire"
      );

      let tresorierType = this.fonctiontList.find(
        (fct) => fct.SlugFonction == "bureau_tresorier"
      );
      if (
        this.structure.STR_Structure.ID_NomCorrespondant &&
        this.structure.STR_Structure.ID_AdresseCorrespondance &&
        (this.utilisateurs.some((user) => user.ID_Fonction == presidentType.id) || this.isStructureEA)
        // &&
        // this.utilisateurs.some(
        //   (user) => user.ID_Fonction == secretaireType.id
        // ) &&
        // this.utilisateurs.some((user) => user.ID_Fonction == tresorierType.id)
      ) {
        return true;
      }
      return false;
    },

    information_salaries() {
      // nb hours sal need to be setted.
      // if nb hours ===0 then collecteur formation pro isnt needed
      if (this.affiliation.SAL_EquivalentTempsPlein == null) {
        return false;
      }
      if (+this.affiliation.SAL_EquivalentTempsPlein == 0) {
        return true;
      } else {
        if (this.affiliation.ID_CollecteurFormationProfessionnelle) return true;
        return false;
      }
    },

    document_ag() {
      const isGeneralMeetingDocValid = (this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.file.id) || (!this.DOC_CompteRenduAg.isNewDoc && this.DOC_CompteRenduAg.data.CheminDocument);

      if (this.currentStructure.STR_Structure.LIST_StructureType.EST_Club) {
        if (
          this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure == "club_association"
          || this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure == "club_section_association"
        ) {
          if (isGeneralMeetingDocValid) {
            return true;
          }
        }

        if (this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure === 'club_etablissement_affilie') {
          const isSupervisorDiplomaDocValid = (this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.file.id) || (!this.DOC_DiplomeEncadrant.isNewDoc && this.DOC_DiplomeEncadrant.data.CheminDocument);
          const isProfessionalCardDocValid = (this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.file.id) || (!this.DOC_CarteProfessionnelle.isNewDoc && this.DOC_CarteProfessionnelle.data.CheminDocument);
          const isInsuranceCertificateDocValid = (this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.file.id) || (!this.DOC_AttestationAssurance.isNewDoc && this.DOC_AttestationAssurance.data.CheminDocument);

          if (
            isSupervisorDiplomaDocValid
            && isProfessionalCardDocValid
            && isInsuranceCertificateDocValid
          ) {
            return true;
          }
        }
      }

      const isStatusDocValid = (this.DOC_Status.isNewDoc && this.DOC_Status.data.file.id) || (!this.DOC_Status.isNewDoc && this.DOC_Status.data.CheminDocument);

      if (
        isGeneralMeetingDocValid
        && isStatusDocValid
      ) {
        return true;
      }

      return false;
    },

    club_activity() {
      if (this.activities.length > 0) return true;
      return false;
    },

    isStructureEA() {
      return this.currentStructure.STR_Structure.ID_TypeStructure === StructureType.ClubEtablissementAffilie;
    },

    DOC_CompteRenduAg() {
      const strDocGeneralMeeting = this.structureDocuments.find(doc => doc.type === GENERAL_MEETING_REPORT);
      const compteRenduAg = this.affiliation.DOC_CompteRenduAg;

      if (strDocGeneralMeeting && compteRenduAg) {
          return new Date(strDocGeneralMeeting.createdAt) > new Date(compteRenduAg.Z_DateCreation) 
              ? { data : strDocGeneralMeeting, isNewDoc: true }  
              : { data: compteRenduAg, isNewDoc: false };
      } else if (strDocGeneralMeeting) {
          return { data : strDocGeneralMeeting, isNewDoc: true };
      } else if (compteRenduAg) {
          return { data: compteRenduAg, isNewDoc: false };
      }

      return this.getDefaultDoc("compte_rendu_ag");
    },

    DOC_Status() {
      const strDocStatus = this.structureDocuments.find(doc => doc.type === RESULT_REPORT);
      const status = this.affiliation.DOC_Status;

      if (strDocStatus && status) {
          return new Date(strDocStatus.createdAt) > new Date(status.Z_DateCreation) 
              ? { data : strDocStatus, isNewDoc: true }  
              : { data: status, isNewDoc: false };
      } else if (strDocStatus) {
          return { data : strDocStatus, isNewDoc: true };
      } else if (status) {
          return { data: status, isNewDoc: false };
      }

      return this.getDefaultDoc("status");
    },

    DOC_AttestationAssurance() {
      const strDocInsuranceCertificate = this.structureDocuments.find(doc => doc.type === INSURANCE_CERTIFICATE);
      const attestationAssurance = this.affiliation.DOC_AttestationAssurance;

      if (strDocInsuranceCertificate && attestationAssurance) {
          return new Date(strDocInsuranceCertificate.createdAt) > new Date(attestationAssurance.Z_DateCreation) 
              ? { data : strDocInsuranceCertificate, isNewDoc: true }  
              : { data: attestationAssurance, isNewDoc: false };
      } else if (strDocInsuranceCertificate) {
          return { data : strDocInsuranceCertificate, isNewDoc: true };
      } else if (attestationAssurance) {
          return { data: attestationAssurance, isNewDoc: false };
      }

      return this.getDefaultDoc("attestation_assurance");
    },

    DOC_CarteProfessionnelle() {
      const strDocProfessionalCard = this.structureDocuments.find(doc => doc.type === PROFESSIONAL_CARD);
      const carteProfessionnelle = this.affiliation.DOC_CarteProfessionnelle;

      if (strDocProfessionalCard && carteProfessionnelle) {
          return new Date(strDocProfessionalCard.createdAt) > new Date(carteProfessionnelle.Z_DateCreation) 
              ? { data : strDocProfessionalCard, isNewDoc: true }  
              : { data: carteProfessionnelle, isNewDoc: false };
      } else if (strDocProfessionalCard) {
          return { data : strDocProfessionalCard, isNewDoc: true };
      } else if (carteProfessionnelle) {
          return { data: carteProfessionnelle, isNewDoc: false };
      }

      return this.getDefaultDoc("copie_cartes_professionnelles");
    },

    DOC_DiplomeEncadrant() {
      const strDocSupervisorDiploma = this.structureDocuments.find(doc => doc.type === SUPERVISOR_DIPLOMA);
      const diplomeEncadrant = this.affiliation.DOC_DiplomeEncadrant;

      if (strDocSupervisorDiploma && diplomeEncadrant) {
          return new Date(strDocSupervisorDiploma.createdAt) > new Date(diplomeEncadrant.Z_DateCreation) 
              ? { data : strDocSupervisorDiploma, isNewDoc: true }  
              : { data: diplomeEncadrant, isNewDoc: false };
      } else if (strDocSupervisorDiploma) {
          return { data : strDocSupervisorDiploma, isNewDoc: true };
      } else if (diplomeEncadrant) {
          return { data: diplomeEncadrant, isNewDoc: false };
      }

      return this.getDefaultDoc("diplome_encadrant");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
