<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon v-if="checked" color="green">mdi-check</v-icon>
      <v-icon v-else color="gray">mdi-minus</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Card title",
    },
  },
};
</script>
